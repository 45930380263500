import React from 'react';

const IconWechat = () => (
  <svg
    viewBox="0 0 24 24"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    // stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xlinkTitle=""
    // className="feather"
  >
    <title>Wechat</title>
    {/* <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm5.147 14.076c.826-.598 1.353-1.483 1.353-2.466 0-1.803-1.753-3.263-3.916-3.263-2.162 0-3.915 1.46-3.915 3.263 0 1.802 1.753 3.262 3.915 3.262.447 0 .879-.063 1.279-.178l.115-.018c.075 0 .143.023.208.061l.857.495.075.024c.072 0 .131-.059.131-.131l-.021-.095-.177-.658-.014-.084c0-.087.044-.165.11-.212zm-6.948-9.332c-2.595 0-4.699 1.753-4.699 3.916 0 1.18.633 2.242 1.623 2.959.08.057.132.15.132.256l-.016.099-.212.79-.025.115c0 .086.07.156.156.156l.091-.029 1.028-.594c.078-.045.16-.072.25-.072l.138.021c.48.138.998.214 1.534.214l.258-.006c-.102-.305-.158-.627-.158-.959 0-1.973 1.919-3.572 4.285-3.572l.256.007c-.354-1.87-2.297-3.301-4.641-3.301zm3.08 6.344c-.288 0-.522-.234-.522-.523 0-.288.234-.522.522-.522.289 0 .522.234.522.522 0 .289-.233.523-.522.523zm2.611 0c-.289 0-.522-.234-.522-.523 0-.288.233-.522.522-.522.288 0 .522.234.522.522 0 .289-.234.523-.522.523zm-7.257-3.055c-.346 0-.627-.28-.627-.626s.281-.627.627-.627c.345 0 .626.281.626.627s-.281.626-.626.626zm3.132 0c-.346 0-.627-.28-.627-.626s.281-.627.627-.627.627.281.627.627-.281.626-.627.626z" /> */}
    <path d="M21.502 19.525c1.524-1.105 2.498-2.738 2.498-4.554 0-3.326-3.237-6.023-7.229-6.023s-7.229 2.697-7.229 6.023c0 3.327 3.237 6.024 7.229 6.024.825 0 1.621-.117 2.36-.33l.212-.032c.139 0 .265.043.384.111l1.583.914.139.045c.133 0 .241-.108.241-.241l-.039-.176-.326-1.215-.025-.154c0-.162.08-.305.202-.392zm-12.827-17.228c-4.791 0-8.675 3.236-8.675 7.229 0 2.178 1.168 4.139 2.997 5.464.147.104.243.276.243.471l-.03.184-.391 1.458-.047.211c0 .16.13.29.289.29l.168-.054 1.899-1.097c.142-.082.293-.133.46-.133l.255.038c.886.255 1.842.397 2.832.397l.476-.012c-.188-.564-.291-1.158-.291-1.771 0-3.641 3.542-6.593 7.911-6.593l.471.012c-.653-3.453-4.24-6.094-8.567-6.094zm5.686 11.711c-.532 0-.963-.432-.963-.964 0-.533.431-.964.963-.964.533 0 .964.431.964.964 0 .532-.431.964-.964.964zm4.82 0c-.533 0-.964-.432-.964-.964 0-.533.431-.964.964-.964.532 0 .963.431.963.964 0 .532-.431.964-.963.964zm-13.398-5.639c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156zm5.783 0c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156z" />
  </svg>
);

export default IconWechat;
